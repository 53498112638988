const statusCampanhaMixin = {
  computed: {
    status() {
      return {
        1: { text: "Aprovada", color: "primary" },
        2: { text: "Análise", color: "warning" },
        3: { text: "Reprovada", color: "red" },
        21: { text: "Andamento", color: "success" },
        22: { text: "Finalizada", color: "info" },
        23: { text: "Expirada", color: "error" },
        24: { text: "Indefinido", color: "secundary" }
      };
    }
  }
};

export default statusCampanhaMixin;
