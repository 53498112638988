<template>
  <v-container class="container-card">
    <v-card>
      <TabelaCampanhasMetas action>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="editarCampanha(item)" color="primary" dark small>
            <v-icon>mdi-eye</v-icon>
          </v-btn>

          <v-btn
            icon
            class="ml-2"
            @click="getResumoCampanha(item)"
            color="primary"
            small
          >
            <v-icon color="orange darken-4">mdi-file-document-outline</v-icon>
          </v-btn>

          <v-btn
            icon
            class="ml-2"
            @click="empresasParticipantes(item)"
            color="green"
            small
          >
            <v-icon>mdi-domain </v-icon>
          </v-btn>
        </template>
      </TabelaCampanhasMetas>
      <ModalEmpresasCampanha
        :dialog="dialog"
        :empresas="listaEpresas"
        @close="dialog = false"
      />
    </v-card>
  </v-container>
</template>

<script>
import serviceEmpresa from "@/services/empresa";
import serviceCampanha from "@/services/campanha";
import jasperService from "@/services/http/jasperService";
import dateMixin from "@/mixins/dateMixin";
import currencyMixin from "@/mixins/currencyMixin";
import TabelaCampanhasMetas from "@/components/campanha/metas/TabelaCampanhasMetas";
import ModalEmpresasCampanha from "@/components/campanha/ModalEmpresasCampanha";
import { mapActions, mapMutations } from "vuex";

export default {
  name: "MetasCampanha",

  mixins: [dateMixin, currencyMixin],

  components: {
    TabelaCampanhasMetas,
    ModalEmpresasCampanha
  },

  data() {
    return {
      listaEpresas: [],
      dialog: false
    };
  },

  mounted() {
    this.fetchView("Metas Campanha");
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    }),
    ...mapMutations({
      setCurrentCampanha: "campanha/setCurrentCampanha"
    }),

    editarCampanha(item) {
      this.setCurrentCampanha(item.id_campanha);
      this.$router.push({
        name: "tipos-metas",
        params: {
          nomeCampanha: item.nome_campanha,
          inicioCampanha: item.data_inicio,
          fimCampanha: item.data_final,
          idCampanha: item.id_campanha
        }
      });
      this.fetchView(`${item.nome_campanha}
        - ${this.format(item.data_inicio)} a
        ${this.format(item.data_final)}`);
    },

    async getResumoCampanha(item) {
      const filters = {
        in_id_campanha: item.id_campanha
      };
      let { data } = await jasperService()
        .campanha()
        .resumoCampanha()
        .show(filters);
      this.generatePdf(data);
    },

    generatePdf(data) {
      var blob = new Blob([data], { type: "application/pdf" });
      var blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    },

    async empresasParticipantes(item) {
      try {
        const idCampanha = item.id_campanha;
        const empresasCampanhas = await serviceCampanha.getEmpresasParticipantes(
          {
            id_campanha: idCampanha
          }
        );
        const empresas = empresasCampanhas.data.data
          .map(item => item.id_empresa)
          .toString();

        const {
          data: { data }
        } = await serviceEmpresa.getEmpresas({ id_empresa: empresas });

        this.listaEpresas = data;
        this.dialog = true;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Não foi possivel buscar as empresas"
        });
      }
    }
  }
};
</script>

<style></style>
